<template>
  <v-dialog v-model="showDialog" scrollable persistent max-width="600">
    <v-card>
      <v-toolbar color="#424242" dark dense flat>
        <v-toolbar-title>{{ $t('generic.lang_endShift') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-currency-field
                  prepend-icon="euro"
                  :label="$t('erp.lang_cashEndTotal')"
                  outlined
                  required
                  v-model="countedEndCash"
                  append-icon="book"
                  :disabled="loadingCashbookValue"
                  :loading="loadingCashbookValue"
                  @click:append="getCashFromCashBook"
                  @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NUMERIC"
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="checkCloseShift(false)" :loading="loading" :disabled="loading">
          {{ $t('generic.lang_endShift') }}
        </v-btn>
      </v-card-actions>

      <v-card-actions style="margin-top: -15px;">
        <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="checkCloseShift(true)" :loading="loading" :disabled="loading">
          {{ $t('generic.lang_endShift') }} + {{ $t('accounting.lang_Printdaily') }}
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDivShiftEnd" style="z-index: 299 !important;">
        <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded"
                            :options="touchKeyboardShiftEnd.options"
                            :defaultKeySet="touchKeyboardShiftEnd.keySet" v-if="touchKeyboardShiftEnd.visible"
                            :layout="touchKeyboardShiftEnd.layout" :cancel="hideTouchKeyboardShiftEnd"
                            :accept="hideTouchKeyboardShiftEnd" :input="touchKeyboardShiftEnd.input"/>
      </div>

    </v-card>

    <!-- ERROR DIALOG -->
    <v-dialog :value="errorMessage.length > 0" persistent max-width="300">
      <v-card>
        <v-card-title>{{ $t('generic.lang_info') }}</v-card-title>
        <v-card-text class="text-center">{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="finishClosingShift(shiftUUID)">{{ $t('generic.lang_next') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TSE EXPORT DIALOG -->
    <FiscalDeviceExportData :show-dialog="showTSEExportDialog" ref="tseExportDialog"
                            @exportDone="finishClosingShift"></FiscalDeviceExportData>
  </v-dialog>
</template>

<script>
import {ENDPOINTS, KEYBOARD} from "../../../config";
import {mapGetters, mapState} from "vuex";
import {Events} from "@/plugins/events";
import FiscalDeviceExportData from "@/components/common/fiscalDevice/FiscalDeviceExportData";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

export default {
  name: "ButtonsPrintDailyReportDialog",

  components: {
    FiscalDeviceExportData
  },

  props: {
    posType: String,
    showDialog: Boolean,
    dontRedirect: Boolean
  },

  data() {
    return {
      countedEndCash: 0.00,
      countedEndCashOrigin: 0.00,
      loading: false,
      loadingCashbookValue: false,
      errorMessage: "",
      KEYBOARD,
      touchKeyboardShiftEnd: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
      showTSEExportDialog: false,
      tseExportData: "",
      shiftUUID: "",
      cashbookEntryUUID: ""
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos',
      'touchkeyboard',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient'
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        //RESET DATA WHEN CLOSING DIALOG
        this.loading = false;
        this.loadingCashbookValue = false;
        this.countedEndCash = 0.00;
        this.countedEndCashOrigin = 0.00;
      } else {
        this.getCashFromCashBook();
      }
    }
  },

  methods: {
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardShiftEnd.input = e.target;
      this.touchKeyboardShiftEnd.layout = e.target.dataset.layout;

      if (!this.touchKeyboardShiftEnd.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardShiftEnd.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardShiftEnd() {
      if (this.touchKeyboardShiftEnd.visible) {
        this.touchKeyboardShiftEnd.visible = false;
      }
    },
    dismissOnScreenKeyboardShiftEnd: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivShiftEnd')) {
        if (!document.getElementById('onScreenKeyboardDivShiftEnd').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardShiftEnd();
          }
        }
      }
    },
    getCashFromCashBook() {
      this.loadingCashbookValue = true;

      this.axios.post(ENDPOINTS.POS.CASHBOOKVALUE, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          if (res.data.value > 0) {
            this.countedEndCash = res.data.value;
            this.countedEndCashOrigin = res.data.value;
          }
        }

        this.loadingCashbookValue = false;
      }).catch(() => {
        this.loadingCashbookValue = false;
      });
    },
    checkCloseShift(printReport = false) {
      this.loading = true;

      //CHECK IF SHIFT ID IS SET
      if (this.pos.activeShift.uuid !== null) {
        if (this.pos.activeShift.uuid.length > 0) {
          this.closeShift(printReport);

          return;
        }
      }

      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          //ATTACH ACTIVE SHIFT
          this.$store.dispatch("pos/updateActiveShift", res.data.activeShift).then(() => {
            this.closeShift(printReport);
          });
        } else {
          this.errorMessage = res.data.msg;
          this.loading = false;
        }
      }).catch(() => {
        this.errorMessage = this.$t("generic.lang_errorOccurred");
        this.loading = false;
      });
    },
    closeShift(printReport = false) {
      this.loading = true;

      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.CLOSE, {
        cashierID: this.api.auth.cashierID,
        countedEndCash: this.countedEndCash,
        shiftUUID: this.pos.activeShift.uuid,
        exportData: this.tseExportData
      }).then((res) => {
        if (res.data.success) {
          let shiftUUID = this.pos.activeShift.uuid;
          this.cashbookEntryUUID = res.data.entryUUID;

          this.$store.dispatch("pos/updateActiveShift", {
            uuid: null
          }).then(() => {
            if (printReport) {
              //GET PRINTING DATA FROM API
              this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTZ, {
                reportID: shiftUUID
              }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                  this.shiftClosed(res, shiftUUID);
                } else {
                  this.loading = false;

                  this.finishClosingShift(shiftUUID);

                  Events.$emit("showSnackbar", {
                    message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
                    color: "error"
                  });
                }
              }).catch(() => {
                this.loading = false;

                this.finishClosingShift(shiftUUID);
                Events.$emit("showSnackbar", {
                  message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
                  color: "error"
                });
              });
            } else {
              this.checkECTerminal(shiftUUID);
            }
          });
        } else {
          this.errorMessage = res.data.msg;
          this.loading = false;
        }
      }).catch(() => {
        this.errorMessage = this.$t("generic.lang_shiftCouldNotBeCompleted");
        this.loading = false;
      })
    },
    shiftClosed(res, shiftUUID) {
      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter,res.data.printXML).then(()=>{
        //CHECK IF REDIRECT
        this.checkECTerminal(shiftUUID);
      }).catch((err)=>{
        this.checkECTerminal(shiftUUID);
        Events.$emit("showSnackbar", {
          message: this.$t("accounting.lang_dailyClosingCouldNotBePrinted"),
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
      })

    },
    checkECTerminal(shiftUUID) {
      this.axios.post(ENDPOINTS.POS.ACTIVEECTERMINAL).then((res) => {
        if(res.data.success) {
          let ecTerminal = res.data.ecTerminal;

          //CHECK IF SHOULD AUTO PRINT EC TERMINAL DAILY REPORT
          if(!ecTerminal.printDailyReport) {
            this.checkTSE(shiftUUID);
            return;
          }

          //CREATE NEW AXIOS INSTANCE
          let zvtAxios = this.axios.create({
            baseURL: 'http://'+ecTerminal.zvtServerIP,
            timeout: 60000000,
            headers: {
              'terminal-id': ecTerminal.id,
              'terminal-ip': ecTerminal.terminalIP,
              'terminal-port': ecTerminal.terminalPort,
              'terminal-password': ecTerminal.terminalPW,
              'terminal-ecr': (ecTerminal.printOnECR !== 0),
            }
          });

          //MAKE REQUEST
          zvtAxios.post('/daily-report').then((res) => {
            if (res.status === 200) {
              //PREPARE PRINTING
              let print = [];

              res.data.printing.forEach((item) => {
                print.push({text: item});
              });

              //ADD FINAL CUT
              print.push({cut: true});

              //START PRINTING
              printDataFromPrinter(this.invoicePrinter,print).then(()=>{
                this.checkTSE(shiftUUID);
              }).catch((err)=>{
                this.checkTSE(shiftUUID);

                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_errorOccurred'),
                  color: "error"
                });
              }).finally(()=>{
                this.loadingReprint = false;
              })

            } else {
              this.checkTSE(shiftUUID);
            }
          }).catch(() => {
            this.checkTSE(shiftUUID);
          });
        }
        else {
          this.checkTSE(shiftUUID);
        }
      }).catch(() => {
        this.checkTSE(shiftUUID);
      });
    },
    checkTSE(shiftUUID) {
      this.shiftUUID = shiftUUID;

      //CHECK IF TSE CLIENT IS SETUP
      if (this.fiscalClient === null) {
        this.finishClosingShift();
        return;
      }

      let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (!tseDevice.tseReady) {
        this.errorMessage = this.$t("generic.lang_tseIsNotOperational");
        return;
      }

      tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.$nextTick(() => {
                this.finishTransaction(payload.result.output, tseDevice, shiftUUID);
            });
          } else {
            this.errorMessage = this.$t("generic.lang_couldNotCompleteSigning") + ": " + payload.result.result;
          }
        }
      });
    },
    finishTransaction(transactionPayload, tseDevice, shiftUUID) {
      let changedValue = (this.countedEndCash - this.countedEndCashOrigin).toFixed(2);

      //CREATE FAKE ITEM TO USE EXISTING FUNCTION
      let items = [{
        isVoid: false,
        sellPrice: changedValue,
        taxValue: 0,
        amount: 1,
      }];

      tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, transactionPayload, items, 1, 1, []);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            //SAVE FISCAL DATA
            this.saveFiscalData(transactionPayload, payload.result.output, shiftUUID, tseDevice);
            this.exportTSEData(tseDevice, shiftUUID);
          } else {
            this.errorMessage = this.$t("generic.lang_couldNotCompleteSigning") + ": " + payload.result.result;
          }
        }
      });
    },
    saveFiscalData(startTransactionResultOutput = null, finishTransactionResultOutput = null, shiftUUID, tseDevice) {
      //CHECK IF ENTRY WAS CREATED
      if (this.cashbookEntryUUID === null)
        return;

      //SAVE FISCAL DATA INTO DATABASE
      if (startTransactionResultOutput !== null && finishTransactionResultOutput !== null) {
        let payload = {
          fiscalDevice: {
            id: this.fiscalClient.device.id,
            deviceID: this.fiscalClient.device.deviceID
          },
          fiscalClient: {
            id: this.fiscalClient.id,
            clientID: this.fiscalClient.clientID
          },
          tse: {
            serialNumber: tseDevice.storageInfo.tseInformation.serialNumber,
          },
          transaction: {
            processType: "Kassenbeleg-V1",
            startTime: startTransactionResultOutput.logTime,
            stopTime: finishTransactionResultOutput.logTime,
            transactionNumber: startTransactionResultOutput.transactionNumber,
            signatureCounter: finishTransactionResultOutput.signatureCounter,
            signature: finishTransactionResultOutput.signature
          }
        };

        //SAVE FISCAL DATA INTO DATABASE
        this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.UPDATETSEDATA, {
          entryUUID: this.cashbookEntryUUID,
          tsePayload: payload
        }).then(() => {

        }).catch(() => {

        });
      }
    },
    exportTSEData(tseDevice, shiftUUID) {
      this.showTSEExportDialog = true;
      this.$refs.tseExportDialog.checkTSE(shiftUUID);
    },
    finishClosingShift(shiftUUID = "") {
      if (this.dontRedirect) {
        this.$emit("shiftClosed", shiftUUID);
      } else {
        this.$router.replace({
          name: "dashboard"
        });
      }
    },
    cancelDialog() {
      this.$emit("cancelDialog");
    },
    closeDialog() {
      this.$emit("closeDialog");
    }
  },
  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardShiftEnd);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardShiftEnd);
  }
}
</script>
<template>
  <v-container fluid>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-card v-if="!loading" class="transparent" elevation="0">
      <v-card-subtitle :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="elevation-6">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-icon class="ma-2" color="primary" small>calendar_today</v-icon>
            <strong class="pa-1">{{ $t('accounting.lang_lastDailyClosingPeriod') }}:</strong>
            <span>{{ this.startShift + ' - ' + this.endShift }}</span>
          </v-col>

          <v-col cols="12">
            <v-icon class="ma-2" color="primary" small>access_time</v-icon>
            <strong class="pa-1">{{ $t('generic.lang_shiftStarted') }}:</strong>
            <span>{{ this.endShift }}</span>
          </v-col>
        </v-row>
      </v-card-subtitle>

      <v-card-actions v-if="$store.getters['permissions/checkHybridEnabled']('Hybrid')">
        <v-row align="center" justify="center">
          <v-col v-if="$store.getters['permissions/checkPermission']('dailyReportPrinting')" class="text-center mx-auto"
                 cols="12" sm="6">
            <v-card :disabled="loadingZPrinting" :loading="loadingZPrinting" @click="toggleDialog"
                    elevation="5">
              <div style="padding-top: 40px; padding-bottom: 40px;">
                <div class="text-center icons-container pa-3 primary lighten-4 mx-auto">
                  <font-awesome-icon :icon="['fal' , 'print']" class="primary--text" prefix="fal" size="3x"/>
                </div>
                <v-card-title class="text-center">
                  <span class="mx-auto">{{ $t('accounting.lang_Printdaily') }}</span>
                </v-card-title>
              </div>
            </v-card>
          </v-col>

          <v-col class="text-center" cols="12" sm="6">
            <v-card :disabled="loadingXPrinting" :loading="loadingXPrinting" @click="printXReport" elevation="5">
              <div style="padding-top: 40px; padding-bottom: 40px;">
                <div class="text-center icons-container pa-3 blue lighten-5 mx-auto">
                  <font-awesome-icon :icon="['fal' , 'times']" class="success--text" prefix="fal" size="3x"/>
                </div>
                <v-card-title class="text-center">
                  <span class="mx-auto">{{ $t('accounting.lang_DoDayilyX') }}</span>
                </v-card-title>
              </div>
            </v-card>
          </v-col>

          <v-col v-if="$store.getters['permissions/checkPermission']('dailyReportPrinting')" class="text-center mx-auto"
                 cols="12" sm="6">
            <v-card @click="toggleBulkCreationDialog" :disabled="(fiscalClient && fiscalClient.device && fiscalClient.device.type === 'epsonTSE')"
                    elevation="5">
              <div style="padding-top: 40px; padding-bottom: 40px;">
                <div class="text-center icons-container pa-3 primary lighten-4 mx-auto">
                  <font-awesome-icon :icon="['fal' , 'print']" class="primary--text" prefix="fal" size="3x"/>
                </div>
                <v-card-title class="text-center">
                  <span class="mx-auto">{{ $t('accounting.lang_DailyReportBulkCreation') }}</span>
                </v-card-title>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <EndShiftCounting :show-dialog="showPrintXReportDialog" @closeDialog="showPrintXReportDialog = false"
                      @shiftClosed="shiftClosed" dont-redirect/>

    <BulkCreationDialog :show-dialog="showBulkCreationDialog" @closeDialog="showBulkCreationDialog = false" />
  </v-container>
</template>

<script>
import pdf from 'vue-pdf'
import {ENDPOINTS} from "@/config";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faChartBar, faPrint, faTimes, faTimesHexagon} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import ButtonsPrintDailyReportDialog from "../../pos/buttons/ButtonsPrintDailyReportDialog";
import {Events} from "@/plugins/events";
import {mapGetters, mapState} from "vuex";
import EndShiftCounting from "@/components/pos/EndShiftCounting";
import BulkCreationDialog from "@/components/accounting/dailyreports/BulkCreationDialog";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

library.add(
    faPrint, faTimes, faChartBar, faTimesHexagon
);

export default {
  name: "PrintDailyComponent",

  components: {
    EndShiftCounting,
    BulkCreationDialog,
    ButtonsPrintDailyReportDialog,
    pdf,
    'font-awesome-icon': FontAwesomeIcon
  },
  computed: {
    ...mapState([
      'pos',
      'api',
      'tse'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient'
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  data: () => {
    return {
      ENDPOINTS,
      loading: false,
      loadingXPrinting: false,
      loadingZPrinting: false,
      loadingManagerPrinting: false,
      shiftTime: "",
      startShift: "",
      endShift: "",
      dialog: false,
      printType: 'Z',
      reportType: 0, // we use it to determine wich pdf will be the source of iframe
      showPrintXReportDialog: false,
      showBulkCreationDialog: false,
      open_cashDrawer: false
    }
  },

  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.GET).then((res) => {
        if (res.data.success) {
          this.startShift = res.data.shifts.startShift;
          this.endShift = res.data.shifts.endShift;

          this.loading = false;
        }
      });
    },
    toggleBulkCreationDialog() {
      if (Number(this.$store.getters['settings/getSettingValue']('displayOpenTableWarning')) > 0&&this.$store.getters['permissions/checkModule'](2)) {
        this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then(res => {
          if (res.data.success) {
            if (res.data.tables && res.data.tables.length > 0) {
              this.$swal({
                title: this.$t('generic.lang_warning'),
                text: this.$t('generic.lang_dailyReportOpenTablesWarningMSG'),
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText:this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_next'),
                preConfirm: () => {
                  this.showBulkCreationDialog = true
                },
                allowOutsideClick: () => !this.$swal.isLoading,
              });
            } else {
              this.showBulkCreationDialog = true
            }
          }
        }).catch(err => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' : ' + err.message,
            color: "error"
          });
        })
      } else {
        this.showBulkCreationDialog = true
      }
    },
    toggleDialog() {
      if (Number(this.$store.getters['settings/getSettingValue']('displayOpenTableWarning')) > 0&&this.$store.getters['permissions/checkModule'](2)) {
        this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then(res => {
          if (res.data.success) {
            if (res.data.tables && res.data.tables.length > 0) {
              this.$swal({
                title: this.$t('generic.lang_warning'),
                text: this.$t('generic.lang_dailyReportOpenTablesWarningMSG'),
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText:this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_next'),
                preConfirm: () => {
                  this.showPrintXReportDialog = true
                },
                allowOutsideClick: () => !this.$swal.isLoading,
              });
            } else {
              this.showPrintXReportDialog = true
            }
          }
        }).catch(err => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + ' : ' + err.message,
            color: "error"
          });
        })
      } else {
        this.showPrintXReportDialog = true
      }
    },
    getActiveShift() {
      this.axios.post(ENDPOINTS.POS.ACTIVESHIFT.GET, {
        cashierID: this.api.auth.cashierID
      }).then((res) => {
        if (res.data.success) {
          //ATTACH ACTIVE SHIFT
          this.$store.dispatch("pos/updateActiveShift", res.data.activeShift).then(() => {
            this.printXReport();
          });
        } else {
          this.loadingXPrinting = false;

          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        }
      }).catch(() => {
        this.loadingXPrinting = false;

        Events.$emit("showSnackbar", {
          message: this.$t('accounting.lang_currentShiftCouldNotBeRetrieved'),
          color: "error"
        });
      });
    },
    printXReport() {
      this.loadingXPrinting = true;

      //CHECK IF SHIFT ID IS SET
      if (this.pos.activeShift.uuid === null) {
        this.getActiveShift();
        return;
      }

      this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.PRINTX, {
        reportType: "X",
        reportID: this.pos.activeShift.uuid
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          //open cashDrawer if enabled
          let printObj = [...res.data.printXML];

          // EPSON EPOS PRINT
          printDataFromPrinter(this.invoicePrinter,printObj).then(()=>{

          }).catch((err)=>{
            Events.$emit("showSnackbar", {
              message: this.$t('accounting.lang_xClosingCouldNotBeCreated') + " " + err,
              color: "error"
            });
          }).finally(()=>{
            this.loadingXPrinting = false;
          })

        } else {
          this.loadingXPrinting = false;

          Events.$emit("showSnackbar", {
            message: this.$t('accounting.lang_xClosingCouldNotBeCreated'),
            color: "error"
          });
        }
      }).catch(() => {
        this.loadingXPrinting = false;

        Events.$emit("showSnackbar", {
          message: this.$t('accounting.lang_xClosingCouldNotBePrinted'),
          color: "error"
        });
      });
    },
    printManagerReport() {
      this.loadingManagerPrinting = true;
    },
    shiftClosed() {
      this.showPrintXReportDialog = false;
    }
  }
}
</script>

<style scoped>
.buttons {
  min-width: 200px !important;
  height: 200px !important;
  cursor: pointer !important;
}

.icons {
  font-size: 100px !important;
}

#showXReport_iframe {
  width: 100% !important;
  height: 100% !important;
  border: none;
}

#pdf_card {
  width: 100% !important;
  height: 100% !important;
}

.transparent {
  background-color: transparent;
}

.icons-container {
  width: 70px !important;
  height: 70px !important;
  border-radius: 100px !important;
}
</style>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <apexchart :options="this.options" :series="this.series"
                   type="bar" v-if="this.series != null && this.options != null"></apexchart>
      </v-col>

      <v-col cols="12">
        <v-data-table
            :headers="this.headers"
            :items="this.UMSATZCCASHIERTABLE"
            :sort-by="['date']"
            :sort-desc="[false, true]"
            class="elevation-0"
            multi-sort
            v-if="this.UMSATZCCASHIERTABLE != null"
        >

          <template v-slot:item.umsatz="{ item }">
            {{ item.umsatz | currency }}
          </template>

          <template v-slot:item.solds="{ item }">
            {{ item.solds }}
          </template>
          <template v-slot:item.umsatzPerSold="{ item }">
            {{ item.umsatzPerSold | currency }}
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <v-btn :disabled="loadingPDF" @click="showPrintersDialog(item)" rounded class="ma-1 btn-sm" color="primary" icon
                     type="button">
                <v-icon size="23" >receipt</v-icon>
              </v-btn>
              <v-btn @click="exportPDF(item)" class="ma-1 btn-sm" color="warning" icon
                     type="button">
                <v-icon size="23">picture_as_pdf</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <print-waiter-sales-component v-if="this.printers_dialog" v-model="printers_dialog" @closed="printersDialogClosed"
                                  :user="{userID:this.selected_user.name.split(' - ')[0]}"
                                  :start_date="this.start"
                                  :end_date="this.end"/>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import VueApexCharts from 'vue-apexcharts'
import PrintWaiterSalesComponent from "../user/PrintWaiterSalesComponent";
import {Events} from "../../../plugins/events";
import FileSaver from 'file-saver';

var self=null;
export default {
  name: "UmsatzWareGroupComponent",
  components: {
    PrintWaiterSalesComponent,
    'apexchart': VueApexCharts
  },
  props:{
    start: {
      type:Number,
      required:false,
      default:0,
    },
    end: {
      type:Number,
      required:false,
      default:0,
    },
  },
  data: () => {
    return {
      ENDPOINTS,
      options: null,
      series: null,
      loadingPDF: false,
      UMSATZCCASHIERTABLE: null,
      printers_dialog:false,
      selected_user:'',
    }
  },
  mounted() {
    this.loadData();
    self=this;
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('settings.lang_operator'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: this.$t('accounting.lang_turnover'), value: 'umsatz'},
        {text: this.$t('generic.lang_sales'), value: 'solds'},
        {text: this.$t('accounting.lang_turnover')+ '/' + this.$t('generic.lang_Verkauf'), value: 'umsatzPerSold'},
        {
          text: this.$t('accounting.lang_returns'), value: 'retouren'
        },
        {
          text: this.$t('generic.lang_actions'),
          value: "action",
          align: "center",
        },
      ]
    }
  },
  methods: {
    exportPDF(item) {
      this.axios.post(ENDPOINTS.ACCOUNTING.WAITERSALES.PRINTA4,
          {
            startDate:this.start,
            endDate:this.end,
            userID:item.name.split(' - ')[0],
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD pdf
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), item.name+'.pdf');
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
    showPrintersDialog(user) {
      this.selected_user = user
      this.printers_dialog = true;
    },
    printersDialogClosed() {
      this.selected_user = '';
    },
    loadData() {
      const chart = this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.UMSATZCASHIER, {
            reportID: this.$route.params.id
          }),
          table = this.axios.post(ENDPOINTS.ACCOUNTING.DAILYREPORT.UMSATZCASHIERTABLE, {
            reportID: this.$route.params.id
          });

      Promise.all([chart, table]).then(this.axios.spread((...res) => {
        this.UMSATZCCASHIERTABLE = res[1].data.data;

        if (res[0].data && res[0].data.success) {

          this.series = [{
            data: res[0].data.data.map(d => d.Umsatz),
            name: this.$t('generic.lang_sale'),
          }];
          this.options = {
            chart: {
              type: 'bar'
            },
            theme: {
              mode: this.$vuetify.theme.dark? 'dark' : 'light',   
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val | currency;
              },
              offsetY: -20,
              style: {
                fontSize: '14px',
                colors: ["#304758"]
              }
            },
            yaxis: [
              {
                title: {
                  text: this.$t('generic.lang_total')
                },
                labels: {
                  formatter: function (val) {
                    return self.$options.filters.currency(val);
                  }
                }
              },
            ],
            xaxis: {
              title: {
                text: this.$t('settings.lang_operator')
              },
              categories: res[0].data.data.map(d => d.name + ''),
            }
          };

        }


      }));
    },
  }

}
</script>

<style scoped>

</style>
<template>
  <v-dialog v-model="showDialog" fullscreen persistent>
    <v-card tile>
      <v-card-title
        class="pa-2"
        v-bind:style="{
          background: this.$vuetify.breakpoint.smAndDown
            ? '#FF6969'
            : '#2a5298',
        }"
      >
        <v-btn
          @click="closeDialog"
          class="pl-0 ma-0"
          text
          :color="this.$vuetify.breakpoint.smAndDown ? 'white' : 'warning'"
        >
          <v-icon size="40px"> mdi-chevron-left </v-icon>
          <span>{{$t('generic.lang_prev')}}</span>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-15">
        <v-row class="justify-center">
          <v-col cols="12" md="8" lg="6">
            <v-select
              v-model="mode"
              :items="cashStockOptions"
              item-text="text"
              item-value="value"
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="mode == 'b'" class="mb-8 justify-center">
          <v-col cols="12" md="8" lg="6">
            <v-currency-field
              v-model="cashStock"
              prepend-inner-icon="euro"
              :label="$t('erp.lang_cashEndTotal')"
              outlined
            ></v-currency-field>

            <CashoutNumpad
              @change="update"
              @del="del"
              :button-style="$vuetify.breakpoint.smAndDown ? { height: 60, padding: 5 } : { height: 75, padding: 7 }"
              double-zero
            />
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col
            cols="12"
            md="8"
            lg="6"
            class="d-flex flex-wrap justify-space-between"
          >
            <v-btn
              :disabled="loading"
              :loading="loading"
              @click="closeDialog"
              color="error"
              text
              class="ma-0"
            >
              {{ this.$t("generic.lang_prev") }}
            </v-btn>

            <v-btn
              :disabled="loading"
              :loading="loading"
              @click="createDailyReportAllCashiers"
              color="success"
              elevation="0"
              class="ma-0"
            >
              {{ this.$t("accounting.lang_DailyReportBulkCreation") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import CashoutNumpad from "@/components/pos/cashout/CashoutNumpad";

export default {
  name: "BulkCreationDialog",
  components: { CashoutNumpad },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      cashStock: 0.0,
      mode: "a",
      cashStockOptions: [
        {
          text: this.$t("accounting.lang_setCashstockTo0"),
          value: "a",
        },
        {
          text: this.$t("accounting.lang_setCashstockToSpecificValue"),
          value: "b",
        },
        {
          text: this.$t("accounting.lang_setCashstockToActualCashstock"),
          value: "c",
        },
      ],
    };
  },
  methods: {
    createDailyReportAllCashiers() {
      var data = { mode: this.mode };

      if (this.mode == "b") {
        data = {
          mode: this.mode,
          value: this.cashStock,
        };
      }

      this.loading = true;

      this.axios
        .post(ENDPOINTS.POS.ACTIVESHIFT.CLOSE_ACTIVE_SHIFTS, data)
        .then((res) => {
          if (res.data.success) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_success"),
              color: "success",
            });

            this.closeDialog();
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(value) {
      this.cashStock = Number(this.cashStock) * 10;
      this.cashStock += Number(value) / 100;
    },
    del() {
      this.cashStock = 0.0;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
